import React, { Component } from 'react'
import { CVForm } from '../forms/CVForm'
import rocketIcon from '../../assets/img/rocket-icon.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6'
import { graphql, StaticQuery } from 'gatsby'

const options = {
  reserveScrollBarGap: true,
  allowTouchMove: el => {
    while (el) {
      if (el.id === 'body-scroll-lock-ignore') {
        return true
      }
      el = el.parentNode
    }
  },
}

export function cvModalOpen() {
  const body = document.body
  disableBodyScroll(body, options)
  const modal = document.querySelector('.div-cv-modal')
  modal.classList.remove('hidden')
  modal.classList.add('open')
}

class CVModal extends Component {
  constructor(props) {
    super(props)
    this.state = { modalKey: 0 }
  }

  cvModalClose() {
    const modal = document.querySelector('.div-cv-modal')
    modal.classList.remove('open')
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        modal.classList.add('hidden')
        this.setState({ ...this.state, modalKey: this.state.modalKey + 1 })
        const body = document.body
        enableBodyScroll(body, options)
      }, 300)
    }
  }

  submitAnimation() {
    const modal = document.querySelector('.div-cv-modal')
    modal.classList.add('slide-up')
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        this.cvModalClose()
      }, 3000)
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query CVModal {
            prismicCvForm {
              data {
                title
                content
                job_roles {
                  role
                }
              }
            }
          }
        `}
        render={data => (
          <div key={`cv-modal-${this.state.modalKey}`} className='div-cv-modal hidden' onClick={() => this.cvModalClose()}>
            <div className='div-background-sliding-modal'>
              <div className='div-sliding-modal' id='body-scroll-lock-ignore' onClick={(e) => e.stopPropagation()}>
                <div style={{ width: '100%', flex: 1 }}>
                  <CVForm onClose={() => this.submitAnimation()} data={data.prismicCvForm.data} />
                </div>
              </div>
              <span className='quint-heading animation-text animation-text-heading font-main'>Your CV has been uploaded!</span>
              <span className='quint-subtitle animation-text animation-text-center font-main'>A group of highy trained rocket scientists will examine it and get back to you</span>
              <img className='animation-icon-bottom' src={rocketIcon} alt='' />
            </div>
          </div>
        )}
      />
    )
  }
}

export default CVModal
