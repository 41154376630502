import React from 'react'
import { cvModalOpen } from '../CVModal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { offerModalOpen } from '../OfferModal'
import { contactModalOpen } from '../ContactModal'
import { Link } from 'gatsby'
import { LoadableQuintFooter } from '../LoadableComponents'
import { FooterForm } from '../forms/FooterForm'

const Footer = (props) => {
  const { footer } = props
  if (!footer) {
    return ''
  }

  const newIcons = footer.footer_icons.map(item => {
    return { icon: item.footer_icon.url, link: item.footer_icon_link }
  })

  const isMobile = useBreakpoint('xs', false)

  const url = typeof window !== 'undefined' ? window.location.href : ''
  const splitUri = url.split('/')
  const uri = splitUri[splitUri.length - 1]

  const ContentLeft = () => (
    <div
      className='font-main quint-body color-primary' style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: isMobile ? 'center' : 'flex-start'
      }}>
      <div
        style={{
          width: isMobile ? '50%' : 160,
          marginRight: isMobile ? 0 : 32,
          marginBottom: 48,
          fontSize: 16,
          cursor: 'pointer',
          textAlign: 'left'
        }}
        onClick={() => contactModalOpen()}>
        Let's talk
      </div>
      <div
        style={{
          width: isMobile ? '50%' : 160,
          marginRight: isMobile ? 0 : 32,
          marginBottom: 48,
          fontSize: 16,
          cursor: 'pointer',
          textAlign: 'left'
        }}
        onClick={() => offerModalOpen()}>
        Estimate project
      </div>
      <div
        style={{
          width: isMobile ? '50%' : 160,
          marginRight: isMobile ? 0 : 32,
          marginBottom: 48,
          fontSize: 16,
          cursor: 'pointer',
          textAlign: 'left'
        }}
        onClick={() => cvModalOpen()}>
        Work with us
      </div>
      <Link
        to='/portfolio'
        style={{ textDecoration: 'none', width: isMobile ? '50%' : 160 }}>
        <div
          className='font-main quint-body color-primary'
          style={{
            marginRight: isMobile ? 0 : 32,
            marginBottom: 48,
            fontSize: 16,
            cursor: 'pointer',
            textAlign: 'left'
          }}>
          Our work
        </div>
      </Link>
    </div>
  )

  const Details = () => (
    <div style={isMobile ? {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    } : { display: 'flex', alignItems: 'center' }}>
      {footer.footer_details && footer.footer_details.map((item, index) => (
        <div
          className='font-main quint-text-medium color-primary'
          style={isMobile
            ? { textAlign: 'left', marginBottom: 16 }
            : {
              width: index === 0 ? 160 : 'fit-content',
              marginRight: index === 0 ? 32 : 72
          }}
          key={index}>
          {item.footer_details_text}
        </div>
      ))}
    </div>
  )

  const Copyright = () => (
    <div
      className='font-main quint-text-regular color-primary-medium'
      style={{ fontSize: 12 }}>
      © {(new Date()).getFullYear()} Quintessential SFT All rights reserved
    </div>
  )

  if (uri === 'portfolio') {
    return null
  }

  return (
    <LoadableQuintFooter
      title={footer.title}
      icons={newIcons}
      background='#ffffff'
      textColor='#25317B'
      mapLink={footer.footer_map_link}
      contentLeft={<ContentLeft />}
      contentRight={<FooterForm />}
      details={<Details />}
      copyright={<Copyright />}
    />
  )
}

export default Footer
