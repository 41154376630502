module.exports = {
  siteTitle: 'Quintessential SFT', // Site title.
  siteTitleAlt: 'Quintessential', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.quintessential.gr', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'More than a digital product agency.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-5XTBGKT', // GTM tracking ID.
  disqusShortname: 'quintessential', // Disqus shortname.
  userName: 'Quintessential',
  userTwitter: 'quintessential',
  userLocation: 'Athens, Greece',
  userDescription: '',
  copyright: 'Copyright © Quintessential SFT 2018-2024. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#030F4B', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  gaTrackingId: 'UA-127581126-1',
  hotjarId: '1090853',
  hotjarSv: '6',
  pixelId: '274179093303858',
  sentryDsn: 'https://f3daa8cac3b643f7b105334220b0cc8b@sentry.io/1876658',
  prismic_repositoryName: 'quintessentialwebsite',
}
