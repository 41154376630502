import React, { Component } from 'react'
import { ContactForm } from '../forms/ContactForm'
import ContactUsIcon from '../../assets/img/contact-us-icon.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6'

const options = {
  reserveScrollBarGap: true,
  allowTouchMove: el => {
    while (el) {
      if (el.id === 'body-scroll-lock-ignore') {
        return true
      }
      el = el.parentNode
    }
  },
}

export function contactModalOpen() {
  const body = document.body
  disableBodyScroll(body, options)
  const modal = document.querySelector('.div-contact-modal')
  modal.classList.remove('hidden')
  modal.classList.add('open')
}

class ContactModal extends Component {
  constructor(props) {
    super(props)
    this.state = { modalKey: 0 }
  }

  contactModalClose() {
    const modal = document.querySelector('.div-contact-modal')
    modal.classList.remove('open')
    if (typeof window !== 'undefined') {
      window.setTimeout(() => {
        modal.classList.add('hidden')
        const body = document.body
        enableBodyScroll(body, options)
        this.setState({ ...this.state, modalKey: this.state.modalKey + 1 })
      }, 300)
    }
  }

  submitAnimation() {
    const modal = document.querySelector('.div-contact-modal')
    // const backgroundSlidingModal = document.querySelector('.div-background-sliding-modal')
    // const icon = document.querySelector('.animation-icon')
    // moveCenterRotate(1, icon, backgroundSlidingModal)
    modal.classList.add('slide-up')
    if (typeof window !== 'undefined') {
      window.setTimeout(() => {
        this.contactModalClose()
      }, 3000)
    }
  }

  render() {
    return (
      <div
        key={`contact-modal-${this.state.modalKey}`} className='div-contact-modal hidden' onClick={() =>
          this.contactModalClose()}>
        <div className='div-background-sliding-modal'>
          <div className='div-sliding-modal' id='body-scroll-lock-ignore' onClick={(e) => e.stopPropagation()}>
            <div className='d-flex align-items-center' style={{ width: '100%', height: '8vh', marginBottom: '8px', paddingRight: '12vh' }}>
              <span
                className='quint-heading font-main color-primary-dark'
                style={{ fontWeight: 400, letterSpacing: '0.03em', textAlign: 'left' }}>Contact us</span>
            </div>
            <div className='d-flex align-items-center' style={{ width: '100%', marginBottom: '32px' }}>
              <span
                className='quint-heading font-main color-primary-medium'
                style={{ fontWeight: 300, letterSpacing: '0.03em', textAlign: 'left' }}>
                What are you looking for? Drop us a line
              </span>
            </div>
            <div style={{ width: '100%', flex: 1 }}>
              <ContactForm onClose={() => this.submitAnimation()} />
            </div>
          </div>
          <img className='animation-icon' src={ContactUsIcon} alt='' />
          <span className='quint-subtitle animation-text font-main'>Message sent!</span>
        </div>
      </div>
    )
  }
}

export default ContactModal
