import React, { useState } from 'react'
import quintLogov2 from '../../assets/img/quint-logo-transparent.svg'
import { offerModalOpen } from '../OfferModal'
import { navigate } from 'gatsby-link'
import mobileCloseIcon from '../../assets/img/header-icon.svg'
import MenuV2 from '../MenuV2'
import { LoadableQuintButton, LoadableQuintNavHeader } from '../LoadableComponents'

const tabsUri = [
  '/',
  '/portfolio',
  '/what-we-do',
  '/blog',
]

const NavBar = React.memo(props => {
  const { headerData, selected } = props

  const arrayData = headerData.header_tabs.map((item) => {
    return item.header_tab
  })
  const [menuOpen, setMenuOpen] = useState(false)

  const handleTabSelect = (index) => {
    navigate(`${tabsUri[index]}`)
  }

  const handleModalNavigation = (index) => {
    navigate(`${tabsUri[index]}`)
    handleModalClose()
  }

  const handleModalClose = () => {
    setMenuOpen(false)
  }

  return (
    <div id='nav-wrapper'>
      <>
        <LoadableQuintNavHeader
          key='quint-nav-header-main'
          logo={quintLogov2}
          tabs={arrayData}
          selectedTab={selected}
          setSelectedTab={handleTabSelect}
          color='#25317B'
          backgroundColor='#fff'
          borderColor='#EFF8FF'
          button={<LoadableQuintButton
            className='font-main quint-button button-no-outline'
            rounded='contained' border={false} textColor='#ffffff' background='#25317B' borderRadius='8px' borderColor='#25317B'
            paddingY='12px'
            paddingX='24px' onClick={() => offerModalOpen()}>
            {headerData.header_button}
          </LoadableQuintButton>}
          buttonMobile={<LoadableQuintButton
            className='display-none-on-medium'
            iconRight={mobileCloseIcon}
            textColor='#25317b'
            width='88px'
            iconMargin='8px'
            rounded='rounded' onClick={() => setMenuOpen(true)}>
            <div className='font-main' style={{ fontSize: '12px' }}>
              MENU
            </div>
          </LoadableQuintButton>} />
        {/* <Banner isBlog={isBlog} /> */}
        <MenuV2 isOpen={menuOpen} tabs={arrayData} selectedTab={selected} handleModalClose={handleModalClose} handleModalNavigation={handleModalNavigation} />
      </>
    </div>
  )
})

export default NavBar
