import React, { Component } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6'
import { OfferForm } from '../forms/OfferForm'
import { graphql, StaticQuery } from 'gatsby'
import CloseIcon from '../../assets/img/x-blue.svg'

const options = {
  reserveScrollBarGap: true,
  allowTouchMove: el => {
    while (el) {
      if (el.id === 'body-scroll-lock-ignore') {
        return true
      }
      el = el.parentNode
    }
  },
}

export function offerModalOpen(selectedDevice = undefined) {
  const body = document.body
  disableBodyScroll(body, options)
  const modal = document.querySelector('.div-offer-modal')
  modal.classList.remove('hidden')
  modal.classList.add('open')
  if (selectedDevice) {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('offer-selected-device', true, true, selectedDevice)
    window.dispatchEvent(event)
  }
}

class OfferModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDevice: undefined,
      modalKey: 0
    }
  }

  offerModalClose() {
    const modal = document.querySelector('.div-offer-modal')
    modal.classList.remove('open')
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        modal.classList.add('hidden')
        this.setState({ ...this.state, selectedDevice: undefined, modalKey: this.state.modalKey + 1 })
      }, 500)
    }
    const body = document.body
    enableBodyScroll(body, options)
  }

  submitAnimation() {
    const modal = document.querySelector('.div-offer-modal')
    modal.classList.add('slide-up')
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        this.offerModalClose()
      }, 3000)
    }
  }

  handleSelectedDevice(ev) {
    this.setState({
      ...this.state,
      selectedDevice: ev.detail
    })
  }

  componentDidMount() {
    window.addEventListener('offer-selected-device', (ev) => this.handleSelectedDevice(ev))
  }

  componentWillUnmount() {
    window.removeEventListener('offer-selected-device', (ev) => this.handleSelectedDevice(ev))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query OfferModal {
            prismicOfferForm {
              data {
                title
                subtitle
                step1_text
                step1_checkboxes {
                  text
                  icon {
                    url
                  }
                }
                step2_text
                step2_checkboxes {
                  icon {
                    url
                  }
                  text
                }
                step3_text
                step3_switch
                consent
              }
            }
          }
        `}
        render={data => (
          <div key={`offer-modal-${this.state.modalKey}`} className='div-offer-modal hidden' onClick={() => this.offerModalClose()}>
            <div className='div-background-sliding-modal-large'>
              <img src={CloseIcon} alt='' className='close-icon' onClick={() => this.offerModalClose()} />
              <div
                className='div-sliding-modal' id='body-scroll-lock-ignore' style={{ backgroundColor: '#F9F9F9' }} onClick={(e) => {
                  e.stopPropagation()
                }}>
                <OfferForm selectedDevice={this.state.selectedDevice} onClose={() => this.submitAnimation()} data={data.prismicOfferForm.data} />
              </div>
              {/* <span className='quint-heading animation-text animation-text-heading font-main'>Your CV has been uploaded!</span> */}
              <span className='quint-subtitle animation-text animation-text-center font-main'>Your offer request has been sent!</span>
            </div>
          </div>
        )}
      />
    )
  }
}

export default OfferModal
