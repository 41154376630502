import React from 'react'
import useBreakpoint from '../../../customHooks/useBreakpoint'

const OfferFormCheckbox = (props) => {
  const { text = '', icon, checked = false, onChange, value, size = '100px', name, ...rest } = props
  const isMobile = useBreakpoint('lg', false)
  const checkboxRef = React.useRef()

  function onRootClick() {
    checkboxRef.current.click()
  }

  return (
    <div
      className={checked ? 'offer-checkbox-root checked' : 'offer-checkbox-root'}
      onClick={onRootClick}
      style={{
        width: isMobile ? '100%' : size,
        height: isMobile ? 52 : size
      }}
      {...rest}>
      <input
        ref={checkboxRef}
        value={value}
        checked={checked}
        onChange={onChange}
        type='checkbox'
        className='checkbox'
        name={name}
        style={{ pointerEvents: 'none' }}
      />
      <img src={icon} alt='' style={isMobile ? { height: '100%', width: 32 } : {}} />
      <span className='offer-checkbox-text'>{text}</span>
    </div>
  )
}

export default OfferFormCheckbox
