import React from 'react'
import { Formik, Field } from 'formik'
import validationSchema from './validationSchema'
import { LoadableQuintButton } from '../../LoadableComponents'
import { contactFormRequest } from '../../../http/formRequests'

const ContactForm = ({ onClose }) => {
  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        contactFormRequest(values.name, values.email, values.message)
          .then(() => {
            onClose()
            setSubmitting(false)
          })
          .catch(error => {
            console.log('contactFormRequest error', error)
            alert('Error: Please Try Again!')                               // eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
        values,
      }) => (<form
        name='contact'
        onSubmit={handleSubmit}
        onReset={handleReset}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'stretch' }}
      >
        <div style={{ flex: 1 }}>
          <div className='field font-main'>
            <div className='control'>
              <Field style={{ fontSize: 16 }} className={values.name ? 'input contained filled' : 'input contained'} type='text' placeholder='What’s your name?' name='name' id='name' />
            </div>
            <small className='color-black-transparent' style={{ display: 'block', height: '13px' }}>{touched.name && errors.name ? errors.name : ''}</small>
          </div>

          <div className='field font-main' style={{ marginTop: '16px' }}>
            <div className='control'>
              <Field style={{ fontSize: 16 }} className={values.email ? 'input contained filled' : 'input contained'} type='email' placeholder='What’s your email?' name='email' id='email' />
            </div>
            <small className='color-black-transparent' style={{ display: 'block', height: '13px' }}>{touched.email && errors.email ? errors.email : ''}</small>
          </div>

          <div className='field font-main' style={{ marginTop: '16px' }}>
            <div className='control'>
              <Field style={{ resize: 'none', fontSize: 16 }} className={values.message ? 'input contained textarea filled' : 'input contained textarea'} component='textarea' placeholder='Write your message...' name='message' id='message' rows='6' />
            </div>
            <small className='color-black-transparent' style={{ display: 'block', height: '13px' }}>{touched.message && errors.message ? errors.message : ''}</small>
          </div>
        </div>

        <div className='field font-main'>
          <div className='control d-flex justify-content-center font-supportive' style={{ position: 'relative' }}>
            <LoadableQuintButton
              className='font-supportive display-none-on-small button-no-outline' rounded='contained' width='200px'
              background={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0) ? '#25317B' : '#E0E0E0'}
              disabled={isSubmitting}
              textColor='#FFFFFF' border={false} onClick={handleSubmit}>SEND MESSAGE
            </LoadableQuintButton>
            <LoadableQuintButton
              className='font-supportive display-none-on-medium' rounded='contained' width='200px'
              background={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0) ? '#25317B' : '#E0E0E0'}
              disabled={isSubmitting}
              textColor='#FFFFFF' border={false} onClick={handleSubmit}>SEND MESSAGE
            </LoadableQuintButton>
            {/* <LoadableQuintMediaContainer */}
            {/*  className='loading-spinner' type='lottie' data={JSON.stringify(loadingLottie)} */}
            {/*  style={isSubmitting */}
            {/*    ? { position: 'absolute', height: '36px', width: '36px', marginLeft: '116px', display: 'block' } */}
            {/*    : { position: 'absolute', height: '36px', width: '36px', marginLeft: '116px', display: 'none' }} /> */}
          </div>
        </div>
      </form>)}
    />
  )
}

export { ContactForm }
