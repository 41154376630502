import axios from 'axios'
import { CLIENT_ID, CLIENT_SECRET, FORM_API_URL } from '../../envs/forms.json'

const axiosFormInstance = axios.create({
  baseURL: FORM_API_URL,
  headers: { clientid: CLIENT_ID, clientsecret: CLIENT_SECRET }
})

/**
 * Description:  CV Form Request
 * @param {string} role
 * @param {string} email
 * @param {string} file
 */
export const cvFormRequest = (
  role,
  email,
  file,
) => {
  return axiosFormInstance.post('/form/CVForm', {
    role: role,
    email: email,
    file: file
  })
}

/**
 * Description:  Offer Form Request
 * @param {string} devices
 * @param {string} offerTypes
 * @param {string} name
 * @param {string} email
 * @param {string} message
 */
export const offerFormRequest = (
  devices,
  offerTypes,
  name,
  email,
  message
) => {
  return axiosFormInstance.post('/form/OfferForm', {
    devices: devices,
    offerTypes: offerTypes,
    name: name,
    email: email,
    message: message
  })
}

/**
 * Description:  Contact Form Request
 * @param {string} name
 * @param {string} email
 * @param {string} message
 */
export const contactFormRequest = (
  name,
  email,
  message,
) => {
  return axiosFormInstance.post('/form/ContactForm', {
    name: name,
    email: email,
    message: message
  })
}
/**
 * Description:  Quote Form Request
 * @param email
 * @param name
 * @param company
 * @param phone
 * @param services
 */
export const quoteFormRequest = (
  email,
  name,
  company,
  phone,
  services
) => {
  return axiosFormInstance.post('/form/QuoteForm', {
    name: name,
    email: email,
    company: company,
    phone: phone,
    services: Object.keys(services).map(key => services[key] ? key : null).filter(key => key !== null).join(', ')
  })
}
