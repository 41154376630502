import { useState, useEffect } from 'react'
import _ from 'lodash'

const getDeviceConfig = (width) => {
  if (width < 576) {
    return 'xs'
  } else if (width >= 576 && width < 768) {
    return 'sm'
  } else if (width >= 768 && width < 992) {
    return 'md'
  } else if (width >= 992 && width < 1200) {
    return 'lg'
  } else if (width >= 1200) {
    return 'xl'
  } else {
    return null
  }
}

const getDeviceIs = (width, limit, up = true) => {
  switch (limit) {
    case 'xs':
      if (up) {
        return true
      } else {
        return (width < 576)
      }
    case 'sm':
      if (up) {
        return (width >= 576)
      } else {
        return (width < 768)
      }
    case 'md':
      if (up) {
        return (width >= 768)
      } else {
        return (width < 992)
      }
    case 'lg':
      if (up) {
        return (width >= 992)
      } else {
        return (width < 1200)
      }
    case 'xl':
      if (up) {
        return (width >= 1200)
      } else {
        return true
      }
    default:
      return null
  }
}

const useBreakpoint = (limit, up = true) => {
  const [breakPointIs, setBreakpointIs] = useState(() => {
    if (typeof window !== 'undefined') {
      return (getDeviceIs(window.innerWidth, limit, up))
    }
    return null
  })

  useEffect(() => {
    let calcInnerWidth

    if (typeof window !== 'undefined') {
      calcInnerWidth = _.throttle(function () {
        setBreakpointIs(getDeviceIs(window.innerWidth, limit, up))
      }, 200)
      window.addEventListener('resize', calcInnerWidth)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', calcInnerWidth)
      }
    }
  }, [])

  return breakPointIs
}

export default useBreakpoint

export const useCurrentBreakpoint = () => {
  const [breakPoint, setBreakpoint] = useState(() => {
    if (typeof window !== 'undefined') {
      return (getDeviceConfig(window.innerWidth))
    }
    return null
  })

  useEffect(() => {
    let calcInnerWidth

    if (typeof window !== 'undefined') {
      calcInnerWidth = _.throttle(function () {
        setBreakpoint(getDeviceConfig(window.innerWidth))
      }, 200)
      window.addEventListener('resize', calcInnerWidth)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', calcInnerWidth)
      }
    }
  }, [])

  return breakPoint
}
