import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  role: Yup.string()
    .required('Job Role is required!'),
  email: Yup.string()
    .email('Enter a valid email!')
    .required('Email is required!'),
})

export default validationSchema
