import React, { useState } from 'react'
import { Field, Formik } from 'formik'
import validationSchema from './validationSchema'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import ThumbsUp from '../../../assets/img/thumbs-up.svg'
import Close from '../../../assets/img/x-blue.svg'
import useBreakpoint from '../../../customHooks/useBreakpoint'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const FooterForm = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const isMobile = useBreakpoint('xs', false)

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleSubmitModal = () => {
    setModalOpen(true)
    window.setTimeout(() => {
      setModalOpen(false)
    }, 4000)
  }

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          addToMailchimp(values.email)
          fetch("/?no-cache=1", {                                 //eslint-disable-line
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': 'footer-email',
              ...values,
            }),
          })
            .then(() => {
              handleSubmitModal()
              setSubmitting(false)
            })
            .catch(error => {
              console.log(error)
              alert('Error: Please Try Again!')                               // eslint-disable-line
              setSubmitting(false)
            })
        }}
        render={({
          handleSubmit,
          handleReset,
          isSubmitting,
        }) => (<form
          name='footer'
          onSubmit={handleSubmit}
          onReset={handleReset}
          className='footer-form'
        >
          <div className='form-content'>
            <label className='form-label'>
              Join us for our weekly blogs:
            </label>
            <div className='input-button-container'>
              <Field
                type='email'
                name='email'
                autoComplete='on'
                placeholder='Drop your email here'
                className='form-input' />
              <button disabled={isSubmitting} className='submit-button' type='submit'>Jump in</button>
            </div>
          </div>
        </form>)}
      />
      <div style={{
        position: 'fixed',
        zIndex: 1000,
        left: 0,
        top: 0,
        width: '100%',
        backgroundColor: '#00000040',
        opacity: modalOpen ? 1 : 0,
        visibility: modalOpen ? 'visible' : 'hidden',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        transition: 'all ease-in-out 1s'
      }}>
        <div
          style={{
            width: isMobile ? '100%' : 700,
            height: 300,
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            backgroundColor: '#fff',
            padding: isMobile ? 32 : 48,
            paddingRight: 24,
            position: 'relative',
            boxShadow: '0px 2px 20px rgba(37, 49, 123, 0.25)',
          }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div className='font-main quint-heading color-primary' style={{ marginBottom: isMobile ? 8 : 16 }}>
              Thank you!
            </div>
            <div
              className='font-main quint-subtitle color-primary'
              style={{ fontWeight: 500, maxWidth: 300, lineHeight: isMobile ? 2.5 : 1.5 }}>
              From now on you will receive an e-mail, whenever we upload something new.
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
            <img src={ThumbsUp} alt='quint-thumbs-up' style={{ height: '90%', width: isMobile ? '32%' : '90%' }} />
          </div>
          <img
            src={Close} alt='' style={{ position: 'absolute', top: 24, right: 32, cursor: 'pointer' }}
            onClick={() => handleCloseModal()} />
        </div>
      </div>
    </>

  )
}
