import React, { useEffect, useRef, useState } from 'react'
import { Formik, Field, FieldArray } from 'formik'
import validationSchema from './validationSchema'
import OfferFormCheckbox from './OfferFormCheckbox'
import Number1 from '../../../assets/img/offer-01.svg'
import Number2 from '../../../assets/img/offer-02.svg'
import Number3 from '../../../assets/img/offer-03.svg'
import Arrow from '../../../assets/img/offer-arrow-right-light.svg'
import useBreakpoint from '../../../customHooks/useBreakpoint'
import { LoadableQuintButton, LoadableQuintIconButton } from '../../LoadableComponents'
import { offerFormRequest } from '../../../http/formRequests'

const OfferForm = ({ onClose, data, selectedDevice }) => {
  if (!data) {
    return ''
  }

  const isMobile = useBreakpoint('lg', false)

  const [formKey, setFormKey] = useState(0)

  const devicesArray = data.step1_checkboxes.map(cb => {
    return { name: cb.text, icon: cb.icon ? cb.icon.url : null }
  })

  const typesArray = data.step2_checkboxes.map(cb => {
    return { name: cb.text, icon: cb.icon ? cb.icon.url : null }
  })

  const topNum = useRef()
  const middleNum = useRef()
  const bottomNum = useRef()

  const [consent, setConsent] = useState(false)

  useEffect(() => {
    if (selectedDevice) {
      const selectedElement = document.getElementById(selectedDevice)
      if (selectedElement && !selectedElement.classList.contains('checked')) {
        selectedElement.click()
      }
    }
  }, [selectedDevice])

  function clearForm() {
    if (typeof document !== 'undefined') {
      setFormKey(formKey + 1)
    }
  }

  return (
    <div
      id='offer-form-div' style={{
        width: '100%',
        height: isMobile ? 'unset' : '100%',
        display: isMobile ? 'initial' : 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        position: 'relative',
      }}>
      <span
        className='font-main quint-heading color-primary-dark'
        style={isMobile ? { textAlign: 'left' } : {}}>{data.title}</span>
      {isMobile
        ? <div
          className='font-main quint-subtitle color-primary'
          style={{ textAlign: 'left', marginBottom: 32, maxWidth: '70%' }}>{data.subtitle}</div>
        : <span className='font-main quint-subtitle color-primary'>{data.subtitle}</span>}
      <Formik
        initialValues={{ devices: [], offerTypes: [], name: '', email: '', message: '' }} // stringify devices and offerTypes
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          offerFormRequest(
            JSON.stringify(values.devices),
            JSON.stringify(values.offerTypes),
            values.name,
            values.email,
            values.message
          )
            .then(() => {
              onClose()
              setSubmitting(false)
              window.setTimeout(() => {
                clearForm()
              }, 600)
            })
            .catch(error => {
              console.log('offerFormRequest error', error)
              alert('Error: Please Try Again!')                               // eslint-disable-line
              setSubmitting(false)
            })
        }}
        render={({
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          handleReset,
          values,
        }) => {
          return (<form
            name='offer'
            onSubmit={handleSubmit}
            onReset={handleReset}
            style={{
              width: '100%',
              height: isMobile ? 'unset' : '100%',
              display: isMobile ? 'initial' : 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'stretch'
            }}
          >
            <div className='font-main' style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: 24 }}>
              <div className='offer-field font-main' style={{ width: '100%' }}>
                <div className='offer-section'>
                  <img src={Number1} alt='' ref={topNum} style={isMobile ? { height: 32, marginBottom: 4 } : {}} />
                  <span className='quint-subtitle'>
                    {data.step1_text}
                  </span>
                </div>
                <div className='control' style={isMobile ? { width: '100%' } : { width: '450px' }}>
                  <FieldArray
                    name='devices'
                    id='devices'
                    render={arrayHelpers => (
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}>
                        {devicesArray.map((device) => (
                          <OfferFormCheckbox
                            name='devices'
                            id={device.name}
                            key={`${device.name}-${formKey}`}
                            value={device.name}
                            checked={values.devices.includes(device.name)}
                            icon={device.icon}
                            text={device.name}
                            size='138px'
                            onChange={e => {
                              if (e.target.checked && !values.devices.includes(device.name)) arrayHelpers.push(device.name)
                              else {
                                const idx = values.devices.indexOf(device.name)
                                arrayHelpers.remove(idx)
                              }
                            }}
                          />
                        ))}
                        <small
                          className='color-black-transparent'
                          style={{
                            position: 'absolute',
                            bottom: -24,
                            right: 0
                          }}>{touched.devices && errors.devices ? errors.devices : ''}</small>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className='font-main'
              style={{ flex: 1, display: 'flex', alignItems: 'center', position: 'relative', marginBottom: 24 }}>
              <div className='offer-field font-main' style={{ width: '100%' }}>
                <div className='offer-section'>
                  <img src={Number2} alt='' ref={middleNum} style={isMobile ? { height: 32, marginBottom: 4 } : {}} />
                  <span className='quint-subtitle'>
                    {data.step2_text}
                  </span>
                </div>
                <div className='control' style={isMobile ? { width: '100%' } : { width: '450px' }}>
                  <FieldArray
                    name='offerTypes'
                    id='offerTypes'
                    render={arrayHelpers => (
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}>
                        {typesArray.map((type) => (
                          <OfferFormCheckbox
                            name='offerTypes'
                            key={`${type.name}-${formKey}`}
                            id={type.name}
                            value={type.name}
                            checked={values.offerTypes.includes(type.name)}
                            icon={type.icon}
                            text={type.name}
                            size='138px'
                            onChange={e => {
                              if (e.target.checked) arrayHelpers.push(type.name)
                              else {
                                const idx = values.offerTypes.indexOf(type.name)
                                arrayHelpers.remove(idx)
                              }
                            }}
                          />
                        )
                        )}
                        <small
                          className='color-black-transparent'
                          style={{
                            position: 'absolute',
                            bottom: -24,
                            right: 0
                          }}>{touched.offerTypes && errors.offerTypes ? errors.offerTypes : ''}</small>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='offer-field font-main' style={{ flex: '1', display: 'flex', marginTop: 8 }}>
              <div className='offer-section'>
                <img src={Number3} alt='' ref={bottomNum} style={isMobile ? { height: 32, marginBottom: 4 } : {}} />
                <span className='quint-subtitle'>
                  {data.step3_text}
                </span>
              </div>
              <div
                className='control field font-main' style={{
                  width: isMobile ? '100%' : '450px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                <div className='control' style={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}>
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row',
                        marginBottom: isMobile ? 16 : 8
                      }}>
                      <Field
                        key='offer-name'
                        style={{
                          maxWidth: isMobile ? 'unset' : 'calc(50% - 4px)',
                          fontSize: 16,
                          marginBottom: isMobile ? 16 : 0
                        }}
                        className={values.name ? 'input contained filled' : 'input contained'} type='text'
                        placeholder='Full name'
                        name='name' id='offer-name' />
                      <Field
                        style={{ maxWidth: isMobile ? 'unset' : 'calc(50% - 4px)', fontSize: 16 }}
                        className={values.email ? 'input contained filled' : 'input contained'}
                        key='offer-email'
                        type='email'
                        placeholder='What’s your email?'
                        name='email' id='email' />
                    </div>
                    <Field
                      component='textarea'
                      style={{ height: 126, fontSize: 16, marginBottom: 0 }}
                      className={values.message ? 'input contained filled textarea' : 'input contained textarea'}
                      type='text'
                      placeholder='Describe your project...'
                      name='message' id='offer-message'
                      key='offer-message' />
                    <div style={{ display: 'flex', justifyContent: 'space-around', height: 24 }}>
                      {touched.name && errors.name &&
                        <small
                          className='color-black-transparent'>{errors.name}</small>}
                      {touched.message && errors.message &&
                        <small
                          className='color-black-transparent'>{errors.message}</small>}
                      {touched.email && errors.email &&
                        <small
                          className='color-black-transparent'>{errors.email}</small>}
                    </div>
                  </div>
                  {!isMobile &&
                    <div style={{ marginBottom: 32, marginLeft: 8 }}>
                      <LoadableQuintIconButton
                        icon={Arrow}
                        borderWidth='2px'
                        iconSize={24}
                        borderColor={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0 && consent) ? '#25317B' : '#B8C3F2'}
                        rounded='circle' size={48}
                        background={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0 && consent) ? '#25317B' : ''}
                        className='button-no-outline'
                        disabled={isSubmitting || !consent}
                        onClick={handleSubmit}
                      />
                    </div>}
                </div>
              </div>
            </div>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'flex-start' : 'flex-end',
              marginBottom: isMobile ? 16 : 0
            }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  fontSize: 10,
                  marginRight: isMobile ? 0 : 56,
                  maxWidth: isMobile ? '100%' : 394
                }} className='font-main color-primary-dark'>
                <input
                  style={{ marginRight: '16px' }} checked={consent}
                  onChange={event => setConsent(event.target.checked)} type='checkbox' className='checkbox-large' />
                {data.consent}
              </div>
            </div>
            {isMobile &&
              <LoadableQuintButton
                boxShadow={Object.keys(errors).length === 0 && Object.keys(touched).length > 0 && consent}
                rounded='contained'
                border={false}
                textColor={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0 && consent) ? '#FFFFFF' : 'rgba(0, 0, 0, 0.26)'}
                background={(Object.keys(errors).length === 0 && Object.keys(touched).length > 0 && consent) ? '#25317B' : 'rgba(0, 0, 0, 0.12)'}
                width='100%'
                className='quint-button font-main text-align-center-on-small button-no-outline'
                disabled={isSubmitting || !consent}
                onClick={handleSubmit}>
                Get an offer
              </LoadableQuintButton>}
          </form>)
        }}
      />
    </div>
  )
}

export { OfferForm }
