import React from 'react'
import CloseIcon from '../../assets/img/x-blue.svg'

const MenuV2 = (props) => {
  const { isOpen, tabs, selectedTab, handleModalClose, handleModalNavigation } = props

  return (
    <>
      <div className={isOpen ? 'menu-mobile-v2 open' : 'menu-mobile-v2'}>
        <div className='modal-container'>
          <img src={CloseIcon} alt='' className='back-icon' onClick={() => handleModalClose()} />
          <div className='tab-container-mobile'>
            {tabs && tabs.map((tab, index) => {
              return (
                <div
                  className='font-main color-primary tab-mobile'
                  style={{
                    opacity: selectedTab === index ? 1 : 0.64
                  }}
                  key={index} onClick={() => handleModalNavigation(index)}>
                  {tab}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuV2
