import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/sass/styles.sass'
import config from '../../../config'
import NavBar from '../NavBar'
import Footer from '../Footer'
import { graphql, StaticQuery } from 'gatsby'
import ContactModal from '../ContactModal'
import CVModal from '../CVModal'
import OfferModal from '../OfferModal'
import ErrorBoundary from '../ErrorBoundary'
import CustomCursor from '../CustomCursor'
import 'quint-ui-react/dist/index.css'

const Layout = ({ children, uri }) => {
  const splitUri = uri.split('/')
  const page = splitUri[splitUri.length - 1]

  const handleTabs = () => {
    if (uri) {
      if (splitUri.includes('blog')) {
        return 3
      }
      switch (page) {
        case '':
          return 0
        case 'portfolio':
          return 1
        case 'what-we-do':
          return 2
        case 'blog':
          return 3
        case 'who-we-are':
          return 0
        case 'product':
          return 0
        default:
          return 1
      }
    }
    return 0
  }

  const [selected, setSelected] = useState(handleTabs)

  useEffect(() => {
    setSelected(handleTabs)
  }, [uri])

  return (
    <StaticQuery
      query={graphql`
          query Layout {
            prismicMenu {
              data {
                menu_items {
                  title
                  url
                }
              }
            }
            prismicHeader {
              data {
                header_tabs {
                  header_tab
                }
                header_button
              }
            }
            prismicFooter {
              data {
                title
                icon {
                  url
                }
                footer_icons {
                  footer_icon {
                    url
                  }
                  footer_icon_link
                }
                title
                footer_details {
                  footer_details_text
                }
                footer_map_link
              }
            }
          }
        `}
      render={data => (
        <ErrorBoundary>
          {splitUri.includes('blog')
            ? ''
            : <Helmet>
              <title>{config.siteTitle}</title>
              <meta name='description' content={config.siteDescription} />
            </Helmet>}
          <NavBar headerData={data.prismicHeader.data} selected={selected} />
          <div id='body-container' style={{ backgroundColor: 'white' }}>
            <CustomCursor />
            {children}
          </div>
          {page !== 'portfolio' &&
            <Footer footer={data.prismicFooter.data} />}
          <ContactModal />
          <CVModal />
          <OfferModal />
        </ErrorBoundary>
      )}
    />
  )
}

export default Layout
