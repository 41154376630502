import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import useBreakpoint from '../customHooks/useBreakpoint'

const CustomCursor = () => {
  const followerRef = useRef()
  const circleRef = useRef()

  const [visible, setVisible] = useState(false)

  const isMobile = useBreakpoint('sm', false)

  const mouseX = (event) => (
    event.clientX
  )

  const mouseY = (event) => (
    event.clientY
  )

  const positionElement = (event) => {
    if (!followerRef.current) return
    const mouse = {
      x: mouseX(event),
      y: mouseY(event)
    }

    followerRef.current.style.transform = `translate(${mouse.x}px, ${mouse.y}px)`

    if (!visible && !isMobile) {
      setVisible(true)
    }
  }

  const shrinkElement = () => {
    if (!circleRef.current) return
    // followerRef.current.style.padding = '4px'
    // circleRef.current.style.width = '32px'
    // circleRef.current.style.height = '32px'

    circleRef.current.style.transform = `scale(0.8)`
  }

  const expandElement = (event) => {
    if (!circleRef.current) return
    // followerRef.current.style.padding = '0px'
    // circleRef.current.style.width = '40px'
    // circleRef.current.style.height = '40px'

    circleRef.current.style.transform = `scale(1)`
  }

  useEffect(() => {
    window.addEventListener('mousemove',
      _.throttle(positionElement, 10)
    )
    window.addEventListener('mousedown',
      shrinkElement
    )
    window.addEventListener('mouseup',
      expandElement
    )

    return () => {
      window.removeEventListener('mousemove',
        _.throttle(positionElement, 10)
      )
      window.removeEventListener('mousedown',
        shrinkElement
      )
      window.removeEventListener('mouseup',
        expandElement
      )
    }
  }, [])

  return (
    <div
      ref={followerRef}
      style={{
        pointerEvents: 'none',
        zIndex: 9999,
        position: 'fixed',
        transition: 'all 60ms ease-in-out',
        display: visible ? 'block' : 'none'
      }}>
      <div
        ref={circleRef}
        style={{
          backgroundColor: '#B8C3F233',
          transition: 'all 200ms ease-in-out',
          boxSizing: 'border-box',
          position: 'absolute',
          borderColor: '#25317B',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '50%',
          height: '40px',
          width: '40px',
          marginTop: '-20px',
          marginLeft: '-20px'
        }} />
    </div>
  )
}

export default CustomCursor
