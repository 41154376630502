import React from 'react'
import { Field, Formik } from 'formik'
import { useDropzone } from 'react-dropzone'
import Select from 'react-select'
import pfdIcon from '../../../assets/img/pdf-icon.svg'
import checkIcon from '../../../assets/img/check-icon.svg'
import alertIcon from '../../../assets/img/alert-icon.svg'
import removeIcon from '../../../assets/img/remove-icon.svg'
import arrowDownL from '../../../assets/img/arrow-down-light.svg'
import validationSchema from './validationSchema'
import { LoadableQuintButton } from '../../LoadableComponents'
import { cvFormRequest } from '../../../http/formRequests'
import useBreakpoint from '../../../customHooks/useBreakpoint'

const SUPPORTED_FORMATS = [
  '.pdf',
  'application/pdf',
]

const CVForm = ({ onClose, data }) => {
  const [fileState, setFileState] = React.useState({})
  const [roleState, setRoleState] = React.useState(undefined)
  const isMobile = useBreakpoint('sm', false)
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: SUPPORTED_FORMATS.join(','),
    multiple: false,
    onDropAccepted: files => handleAttachment(files[0]),
    onDropRejected: () => handleAttachment()
  })

  const options = data && data.job_roles
    ? data.job_roles.map(jr => (
      { value: jr.role, label: jr.role }
    ))
    : []

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: roleState ? '#25317B' : '#B8C3F2',
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: '2px',
      backgroundColor: 'white',
      cursor: 'pointer',
      webkitTransition: 'border-color 300ms ease-in-out',
      transition: 'border-color 300ms ease-in-out',
      '&:hover': {
        borderColor: roleState ? '#25317B' : '#B8C3F2',
      }
    }),
    singleValue: (base) => ({
      ...base,
      color: '#25317B',
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      color: '#25317B',
      backgroundColor: state.isSelected || state.isFocused ? '#EFF8FF' : 'white',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#00000059'
    }),
  }

  function handleAttachment(file) {
    if (file && SUPPORTED_FORMATS.includes(file.type)) {
      handleAccept(file)
    } else {
      handleReject()
    }
  }

  function handleAccept(file) {
    const dropzone = document.querySelector('.dropzone')
    dropzone.classList.remove('rejected')
    dropzone.classList.add('accepted')
    setFileState({ file: file })
  }

  function handleReject() {
    const dropzone = document.querySelector('.dropzone')
    dropzone.classList.remove('accepted')
    dropzone.classList.add('rejected')
    setFileState({})
    window.setTimeout(() => {
      dropzone.classList.remove('rejected')
      setFileState({})
    }, 2000)
  }

  function handleRemove(e) {
    e.stopPropagation()
    const dropzone = document.querySelector('.dropzone')
    dropzone.classList.remove('accepted')
    dropzone.classList.remove('rejected')
    setFileState({})
  }

  function dropzoneMessage() {
    if (typeof document !== 'undefined') {
      const dropzone = document.querySelector('.dropzone')
      if (!dropzone) return ''
      if (dropzone.classList.contains('accepted')) {
        return (
          <div className='dropzone-message'>
            <img src={checkIcon} alt='' />
            <span className='dropzone-title quint-subtitle-small font-main color-primary'>Successfully added</span>
            {fileState.file &&
              <div className='file-preview' style={{ width: '75%', height: '56px' }}>
                <img className='file-preview-img' src={pfdIcon} alt='' />
                <span
                  className='file-preview-text quint-subtitle-small font-supportive'>{fileState.file.name ? fileState.file.name : ''}</span>
                <img className='file-preview-img' src={removeIcon} alt='' onClick={(e) => handleRemove(e)} />
              </div>}
          </div>
        )
      } else if (dropzone.classList.contains('rejected')) {
        return (
          <div className='dropzone-message'>
            <img src={alertIcon} alt='' />
            <span
              className='dropzone-title quint-subtitle-small font-main color-primary'>Only pdf files are accepted</span>
          </div>
        )
      } else {
        return (
          <div className='dropzone-message'>
            <span className='dropzone-title quint-subtitle font-main color-primary display-none-on-small' style={{ fontWeight: 300 }}>Drag and Drop your CV</span>
            <span
              className='dropzone-title quint-subtitle font-main color-primary display-none-on-small'
              style={{ fontWeight: 300 }}>or</span>
            <LoadableQuintButton
              rounded='rounded' textColor='#25317B'
              borderColor='#25317B' className='button-no-outline' type='button'
            >
              BROWSE
            </LoadableQuintButton>
          </div>
        )
      }
    } else {
      return ''
    }
  }

  function clearFile() {
    if (typeof document !== 'undefined') {
      const dropzone = document.querySelector('.dropzone')
      if (dropzone) {
        dropzone.classList.remove('accepted', 'rejected')
        setFileState({})
      }
    }
  }

  return (
    <Formik
      initialValues={{ role: '', email: '', file: undefined }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        const reader = new FileReader()
        reader.readAsDataURL(fileState.file)
        reader.onload = (result) => {
          cvFormRequest(values.role.value, values.email, result.target.result)
            .then(() => {
              onClose()
              setSubmitting(false)
              window.setTimeout(() => {
                clearFile()
              }, 600)
            })
            .catch(error => {
              console.log('cvFormRequest-error', error)                     // eslint-disable-line
              setSubmitting(false)
            })
        }
      }}
      render={({
        values,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <>
            <form
              name='cv'
              onSubmit={handleSubmit}
              onReset={handleReset}
              style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'stretch' }}
            >
              <div className='d-flex justify-content-center flex-column align-items-center font-main' style={{ width: '100%', position: 'relative' }}>
                <img src={arrowDownL} alt='' className='animated infinite bounce-simple medium display-none-on-small' style={{ position: 'absolute', top: '-24px', left: '20em' }} />
                <span className='quint-heading font-main color-primary-dark' style={{ width: '100%', textAlign: 'start' }}>{data && data.title ? data.title : ''}</span>
                <span style={{ borderTop: '1px solid #B8C3F2', width: '100%', position: 'relative', marginTop: '24px', marginBottom: '24px' }}>
                  <div style={{ width: '2vh', height: '2vh', background: '#25317B', borderRadius: '50%', position: 'absolute', left: 0, top: '-1vh' }} />
                </span>
                <p className='paragraph-small font-main color-primary-dark'>{data && data.content ? data.content : ''}</p>
              </div>
              <div className='field font-main'>
                <Select
                  className='cv-select'
                  name='role'
                  placeholder='Select job role'
                  isSearchable={false}
                  options={options}
                  styles={customStyles}
                  value={values.role}
                  onChange={option => {
                    setFieldValue('role', option)
                    setFieldTouched('role', true)
                    setRoleState(option)
                  }}
                  onBlur={() => setFieldTouched('role', true)}
                  style={{ width: '100%' }}
                />
                <small className='color-black-transparent' style={{ display: 'block', height: '13px' }}>{touched.role && errors.role ? errors.role : ''}</small>
              </div>
              <div className='' style={{ marginBottom: 16 }}>
                <div className='control field font-main'>
                  <Field style={{ fontSize: 16, borderWidth: 2 }} className={values.email ? 'input contained filled' : 'input contained'} type='email' placeholder='What’s your email?' name='email' id='email' />
                </div>
              </div>
              <div className='field font-main'>
                <div
                  {...getRootProps({ className: 'dropzone control d-flex flex-column align-items-center justify-content-around' })}
                  style={{ height: isMobile ? '20vh' : '40vh' }}
                >
                  <input name='file' id='file' {...getInputProps()} onChange={(e) => handleAttachment(e.target.files[0])} />
                  {dropzoneMessage()}
                </div>
                <small className='color-black-transparent' style={{ display: 'block', height: '13px' }}>{errors.file ? errors.file : ''}</small>
              </div>

              <div className='field font-main'>
                <div className='control d-flex justify-content-center font-supportive' style={{ position: 'relative' }}>
                  <LoadableQuintButton
                    className='display-none-on-small font-supportive'
                    rounded='contained' width='200px'
                    background={(
                      fileState.file &&
                      Object.keys(errors).length === 0 &&
                      Object.keys(touched).length > 0) ? '#25317B' : '#E0E0E0'}
                    textColor='#FFFFFF'
                    border={false}
                    disabled={
                      isSubmitting || !fileState.file ||
                        Object.keys(errors).length !== 0 ||
                        Object.keys(touched).length === 0
                    }
                    onClick={handleSubmit}>
                    SEND
                  </LoadableQuintButton>
                  <LoadableQuintButton
                    className='display-none-on-medium font-supportive'
                    rounded='contained'
                    width='200px'
                    background={(
                      fileState.file &&
                      Object.keys(errors).length === 0 &&
                      Object.keys(touched).length > 0) ? '#25317B' : '#E0E0E0'}
                    textColor='#FFFFFF'
                    border={false}
                    disabled={
                      isSubmitting || !fileState.file ||
                        Object.keys(errors).length !== 0 ||
                        Object.keys(touched).length === 0
                    }
                    onClick={handleSubmit}>
                    SEND
                  </LoadableQuintButton>

                  {/* <LoadableQuintMediaContainer */}
                  {/*  className='loading-spinner' type='lottie' data={JSON.stringify(loadingLottie)} */}
                  {/*  style={isSubmitting */}
                  {/*    ? { position: 'absolute', height: '36px', width: '36px', marginLeft: '116px', display: 'block' } */}
                  {/*    : { position: 'absolute', height: '36px', width: '36px', marginLeft: '116px', display: 'none' }} /> */}
                </div>
              </div>
            </form>
          </>
        )
      }}
    />
  )
}

export { CVForm }
