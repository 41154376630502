import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Image from '../assets/img/500.svg'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <div style={{
          height: '100vh',
          width: '100vw',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
        }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <div className='quint-heading-big3 font-main color-primary' style={{ marginBottom: 16 }}>
                  Oh, that's a pity...
              </div>
              <div className='quint-subtitle font-main color-primary-dark' style={{ marginBottom: 32 }}>
                  Something went wrong with the loading of this page.
              </div>
              <AniLink
                to='/'
                style={{ textDecoration: 'none' }}>
                {/*<LoadableQuintButton*/}
                {/*  boxShadow='true' rounded='contained' border={false} textColor='#ffffff' background='#25317B'*/}
                {/*  className='text-align-center-on-small'>*/}
                {/*    Back to the site*/}
                {/*</LoadableQuintButton>*/}
              </AniLink>
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Image} alt='quint-500-image' />
          </div>
        </div>
      )
    }

    // when there's not an error, render children untouched
    return this.props.children
  }
}
