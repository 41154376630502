import Loadable from 'react-loadable'
import React from 'react'
import QuintLoading from '../assets/img/quint-loading-gif.gif'
import 'quint-ui-react/dist/index.css'

function Loading() {
  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      zIndex: 10000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={QuintLoading} alt='quintessential-loading' style={{ width: '46px', height: '46px' }} />
    </div>
  )
}

export const LoadableQuintButton = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintButton),
  loading: Loading,
})

export const LoadableQuintMediaContainer = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintMediaContainer),
  loading: Loading,
})

// export const LoadableQuintSnackbar = Loadable({
//   loader: () => import('quint-ui-react').then(module => module.QuintSnackbar),
//   loading: Loading,
// })
//
// export const LoadableQuintProductService = Loadable({
//   loader: () => import('quint-ui-react').then(module => module.QuintProductService),
//   loading: Loading,
// })

export const LoadableQuintProductCard = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintProductCard),
  loading: Loading,
})

export const LoadableQuintProductCardGrid = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintProductCardGrid),
  loading: Loading,
})

export const LoadableQuintCarousel = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintCarousel),
  loading: Loading,
})

export const LoadableQuintTestimonial = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintTestimonial),
  loading: Loading,
})

export const LoadableQuintUnderlineAnimated = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintUnderlineAnimated),
  loading: Loading,
})

export const LoadableQuintIconButton = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintIconButton),
  loading: Loading,
})

export const LoadableQuintFooter = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintFooter),
  loading: Loading,
})

export const LoadableQuintNavHeader = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintNavHeader),
  loading: Loading,
})

// export const LoadableQuintSwitch = Loadable({
//   loader: () => import('quint-ui-react').then(module => module.QuintSwitch),
//   loading: Loading,
// })

export const LoadableQuintSidebar = Loadable({
  loader: () => import('quint-ui-react').then(module => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const event = document.createEvent('Event')
      event.initEvent('QuintSidebar-loaded', true, true)
      window.dispatchEvent(event)
    }
    return (module.QuintSidebar)
  }),
  loading: Loading,
})

export const LoadableQuintTabAnimated = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintTabAnimated),
  loading: Loading,
})

export const LoadableQuintFlipImage = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintFlipImage),
  loading: Loading,
})

export const LoadableQuintContentCard = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintContentCard),
  loading: Loading,
})

export const LoadableQuintValueCard = Loadable({
  loader: () => import('quint-ui-react').then(module => module.QuintValueCard),
  loading: Loading,
})
