const React = require('react')
const Layout = require('./src/components/Layout').default
const ReactDOM = require('react-dom')

exports.onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.pathname)
  window.previousPath = window.locations[window.locations.length - 2]
}

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

exports.wrapPageElement = ({ element, props }) => {
  if (props && props.custom404) {
    return element
  }

  return (
    <Layout {...props}>
      <div style={{ minHeight: '100vh' }}>
        {element}
      </div>
    </Layout>
  )
}
